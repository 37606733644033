import { MatchAPI } from "../src/apis/MatchAPI"
import moment from "moment"
import { getNavigationProps } from "@components/Navigation/getNavigationProps"
import InferNextPropsType from "infer-next-props-type"
import { Col, Row } from "react-bootstrap"
import React from "react"
import { MatchesDate } from "@components/Match/MatchesDate"
import { BookmakerBonusAPI } from "../src/apis/BookmakerBonusAPI"
import { simplifyBookmakerBonus } from "../src/utils/bookmakerBonus"

export default function MatchesListPage({ matches }: InferNextPropsType<typeof getStaticProps>) {
  return (
    <>
      <Row>
        <Col>
          <div className="hs-content-404">
            <p className={"p404"}>Non ha funzionato!</p>
            <p className={"p404description"}>{"Riprovare un'altra volta. Errore 404."}</p>
          </div>
          <div>
            <p className={"p404currentMatches"}>
              Guarda le partite di oggi <span style={{ marginLeft: "5px" }}>↓</span>
            </p>
          </div>
          <div id="hs-content" className={"paddingFive"}>
            <MatchesDate matches={matches} reload={false} />
          </div>
        </Col>
      </Row>
    </>
  )
}

export async function getStaticProps() {
  const title = `404`

  const now = moment()
  const [matches, bookmakerBonuses] = await Promise.all([
    MatchAPI.getPublicMatchesByDate(now.format("YYYY-MM-DD"), false, false, false, false),
    BookmakerBonusAPI.getAll(),
  ])
  const simplifiedBookmakerBonuses = bookmakerBonuses.map((b) => simplifyBookmakerBonus(b))

  return {
    props: {
      matches,
      title,
      navigationProps: await getNavigationProps(),
      bookmakerBonuses: simplifiedBookmakerBonuses,
    },
    revalidate: 10,
  }
}
